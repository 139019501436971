import * as React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import { StatusSelect } from 'src/components/Forms';
import useQueryParams from 'src/lib/useQueryParams';
import { Box, Flex, Loader, SearchInput, Pagination } from '@compono/ui';
import QualificationsList from './QualificationsList';
import { Qualification, Status } from './types';
import Link from '../../components/Link';

interface QualificationsQuery {
  qualifications?: Qualification[];
  qualificationsCount: {
    pages: number;
  };
}

const query = gql`
  query QualificationsQuery(
    $page: Int!
    $status: String
    $orderBy: QualOrderBy
    $order: Order
    $search: String
  ) {
    qualifications(
      page: $page
      status: $status
      orderBy: $orderBy
      order: $order
      search: $search
    ) {
      id
      name
      status
      author {
        id
        firstname
        lastname
        type
      }
      createdAt
      updatedAt
    }
    qualificationsCount(status: $status, search: $search) {
      pages
    }
  }
`;

const QualificationsPage = () => {
  const queryParams = useQueryParams();
  const history = useHistory();

  const page = parseInt(queryParams.get('page') ?? '1', 10);
  const status = queryParams.get('status') ?? 'draft';
  const search = queryParams.get('search') ?? '';

  const getOrderBy = () => {
    if (search) return undefined;
    return status === 'draft' ? 'createdAt' : 'name';
  };

  const { data, loading } = useQuery<QualificationsQuery>(query, {
    variables: {
      page,
      status,
      orderBy: getOrderBy(),
      order: status === 'draft' ? 'desc' : 'asc',
      search: search || undefined,
    },
    fetchPolicy: 'cache-and-network',
  });

  const queryResult = !loading && data ? data : undefined;
  const qualifications = queryResult?.qualifications || [];
  const qualificationsCount = queryResult?.qualificationsCount || { pages: 0 };

  const onChangeFilters = (newStatus: Status, search?: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('status', newStatus);
    searchParams.set('page', '1');
    if (search !== undefined) searchParams.set('search', search);

    history.push(`/qualifications?${searchParams.toString()}`);
  };

  const updateSearchParams = (newPage: number) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set('page', newPage.toString());
    searchParams.set('status', status);
    searchParams.set('search', search);

    return `/qualifications?${searchParams.toString()}`;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <Flex sx={{ flex: 1, alignItems: 'center' }}>
          <Loader alignment={'center'} label={'Loading...'} />
        </Flex>
      );
    }

    if (!queryResult) return null;

    return qualifications.length ? (
      <>
        <QualificationsList qualifications={qualifications} status={status} />
        <Box sx={{ marginTop: 5 }}>
          <Pagination page={page} pages={qualificationsCount.pages} to={updateSearchParams} />
        </Box>
      </>
    ) : (
      <p>No qualifications found...</p>
    );
  };

  return (
    <Flex
      sx={{
        paddingTop: 7,
        paddingBottom: 9,
        flexDirection: 'column',
        minHeight: 'calc(100vh - 56px)', // 56px is the header height
      }}
    >
      <Flex sx={{ gap: 4, paddingTop: 5 }}>
        <StatusSelect value={status} onChange={newStatus => onChangeFilters(newStatus, search)} />
        <Box sx={{ paddingTop: 6, width: '100%' }}>
          <SearchInput
            value={search}
            onChange={newSearch => onChangeFilters(status as Status, newSearch)}
            placeholder="Search qualification name"
          />
        </Box>
      </Flex>

      <Box sx={{ marginTop: 6 }}>{renderContent()}</Box>
    </Flex>
  );
};

export default QualificationsPage;
