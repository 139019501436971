import * as React from 'react';
import AuthorName from 'src/components/Name';
import { Button, EditIcon } from '@shortlyster/ui-kit';
import SimpleDate from 'src/components/Date';
import Link from '../../components/Link';
import { Qualification } from './types';
import {
  HeaderCell,
  StandardTable,
  StandardTableCellProps,
  StandardTableColumn,
  TableCell,
} from '@compono/ui';

interface Props {
  qualifications: Qualification[];
  status: string;
}

const QualificationsList = ({ qualifications = [], status }: Props) => {
  const StringCell = ({ rowData, columnId }: StandardTableCellProps) => {
    const value = rowData[columnId];
    return <TableCell>{value}</TableCell>;
  };

  const DateCell = ({ rowData }: StandardTableCellProps) => {
    return <SimpleDate date={status === 'draft' ? rowData.createdAt : rowData.updatedAt} />;
  };

  const getAuthor = (qual: Qualification) => {
    let author = qual.author;
    if (!author) {
      author = { id: '', firstname: 'UNKNOWN', lastname: 'AUTHOR', type: 'employer' };
    }

    return author;
  };

  const SubmittedByCell = ({ rowData }: StandardTableCellProps) => {
    const author = getAuthor(rowData as Qualification);
    return <AuthorName author={author} />;
  };

  const UserTypeCell = ({ rowData }: StandardTableCellProps) => {
    const author = getAuthor(rowData as Qualification);
    return <TableCell>{author.type.toUpperCase()}</TableCell>;
  };

  const columns: StandardTableColumn[] = [
    {
      id: 'date',
      Header: () => HeaderCell({ label: `Date ${status === 'draft' ? 'submitted' : status}` }),
      Cell: DateCell,
      width: 0.5,
    },
    {
      id: 'name',
      Header: () => HeaderCell({ label: 'Name' }),
      Cell: StringCell,
      width: 2,
    },
    {
      id: 'submittedBy',
      Header: () => HeaderCell({ label: 'Submitted by' }),
      Cell: SubmittedByCell,
      width: 0.5,
    },
    {
      id: 'userType',
      Header: () => HeaderCell({ label: 'User type' }),
      Cell: UserTypeCell,
      width: 0.5,
    },
    {
      id: 'modify',
      Header: () => HeaderCell({ label: 'Modify' }),
      Cell: ({ rowData }) => (
        <Link to={`/qualifications/${rowData.id}`}>
          <Button icon={EditIcon}>Edit</Button>
        </Link>
      ),
      width: 0.5,
    },
  ];

  return <StandardTable data={qualifications} columns={columns}></StandardTable>;
};

export default QualificationsList;
